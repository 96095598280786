import React from "react";


const ResumePage = () => {

    return <div className="fadeQuick" style={{width: '100vw', height: 'calc(100vh - 50px)', marginTop: 50}}>
    
        <object data="/resources/Documents/Resume.pdf" type="application/pdf" width="100%" height="100%">
            
        </object>   
    </div>

}

export default ResumePage;